        cursor: pointer;
        <!--outer_clear
 * @Author: your name
 * @Date: 2021-12-07 15:40:36
 * @LastEditTime: 2023-02-10 15:38:29
 * @LastEditors: 韩鸿 1014315067@qq.com
 * @Description: 新闻动态
 * @FilePath: \new-website-1123\src\views\news\home\index.vue
-->
<template>

  <div class="news-home">
   <div class="industry">
    <ul class="news-home-ul"  v-show="newList.length != 0">
      <li class="outer_clear" >
        <div class="news-home-ul-text animation-3">
          <w-title
            model="行业"
            modelkey="动态"
            direction="left"
          ></w-title>
          <div
            class="news-home-ul-article animation-3"
            v-for="item in newList"
            :key="item.id"
            @click="goNewsDetails(item.id)"
          >
            <div class="news-home-ul-article-img">
              <img :src="item.imageUrl" />
            </div>
            <div class="news-home-ul-article-info">
              <div class="news-home-ul-article-time">
                  <span class="year">{{ item.createTime.slice(0,4) }}</span>
                  <div>
                    <span class="month">{{ item.createTime.slice(5,7) }}/{{ item.createTime.slice(8,10) }}</span>
                  <span class="day">{{ item.createTime.slice(11,16) }}</span>
                  </div>
              </div>
              <h2>{{ item.name }}</h2>
              <h4>{{ clearHtml(item.info) }}</h4>
              <img
                class="ml10"
                width="40"
                src="https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/common/jr.png"
                alt="上太科技"
              />

            </div>


          </div>
        </div>

      </li>
    </ul>

   </div>

   <div class="news ">
    <ul class="news-home-ul" v-show="news.length != 0">
      <li class="outer_clear" >
        <div class="news-home-ul-text animation-3">
          <w-title
            model="上太"
            modelkey="新闻"
            direction="left"
          ></w-title>

          <div class="news-box-info animation-3" >
            <div class="news-info-left" v-for="preItem in news.slice(0,1)" :key="preItem.id"  @click="goNewsDetails(preItem.id)">
                <div class="imgs"><img class="bg" :src="preItem.imageUrl" />
                  <img
                class="ml10"
                width="2.0833vw"
                src="https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/common/jr.png"
                alt="上太科技"
              />
                </div>
                <div class="news-info-time">
                  <span class="year">{{ preItem.createTime.slice(0,4) }}</span>
                  <div>
                    <span class="month">{{ preItem.createTime.slice(5,7) }}/{{ preItem.createTime.slice(8,10) }}</span>
                  <span class="day">{{ preItem.createTime.slice(11,16) }}</span>
                  </div>
              </div>
              <h3>{{ preItem.name }} </h3>

          </div>
          <div class="news-info-right">
            <div class="news-info"  v-for="(item,index) in news.slice(1)" :key="index" @click="goNewsDetails(item.id)">
                <div class="imgs"><img :src="item.imageUrl" /></div>
                <div class="news-info-time">
                  <span class="year">{{ item.createTime.slice(0,4) }}</span>
                  <div>
                    <span class="month">{{ item.createTime.slice(5,7) }}/{{ item.createTime.slice(8,10) }}</span>
                  <span class="day">{{ item.createTime.slice(11,16) }}</span>
                  </div>

              </div>
              <h3>{{ item.name }}<img
                class="ml10"
                src="https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/common/jr.png"
                alt="上太科技"
              /></h3>

            </div>
          </div>

          </div>
        </div>
      </li>
    </ul>
   </div>

   <div class="images">
    <ul class="news-home-ul" v-show="images.length != 0">
      <li class="outer_clear" >
        <div class="news-home-ul-text animation-3">
          <w-title
            model="影像"
            modelkey="风采"
            direction="left"
          ></w-title>
          <div class="images-info" >
              <div class="images-info-box" v-for="item in images" :key="item.id" @click="goNewsDetails(item.id)">
                <img :src="item.imageUrl" >
                <div class="news-info-time">
                  <span class="year">{{ item.createTime.slice(0,4) }}</span>
                  <div clas="time">
                    <span class="month">{{ item.createTime.slice(5,7) }}/{{ item.createTime.slice(8,10) }}</span>
                  <span class="day">{{ item.createTime.slice(11,16) }}</span>
                  </div>
              </div>
              <h3>{{ item.name }}

              </h3>
              <img
                class="ml10"
                width="2.0833vw"
                src="https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/common/jr.png"
                alt="上太科技"
              />
              </div>

          </div>

        </div>
      </li>
    </ul>
   </div>


   <div class="container">

</div>

  </div>
</template>

<script>
import {clearHtml} from '@/utils/removeTag.js'

import wTitle from "@/components/sTitle/viewtitle";
import {getNesListApi} from '@/api/index.js'
export default {
  name: "news-home",
  components: {
    wTitle,
  },
  data() {
    return {
      newList: [],
      news:[],
      images:[ ]
    };
  },
  created() { this.getNewsData()},
  computed: {},
  mounted(){

  },
  methods: {
    goMore(data) {
      this.$router.push(data);
    },
    goNewsDetails(id) {
      this.$router.push({
        path: "/news/details",
        query: { id: id }
      });
    },
   async getNewsData(){
     await getNesListApi({pageNum:1,pageSize:999}).then(res=>{
      const result = res.data.data.list.filter(el =>  el.status == 1 );
        this.news = result.filter(el =>  el.type == '上太新闻' ).slice(0,3);
        this.newList = result.filter(el =>  el.type == '行业动态' ).slice(0,4);
        this.images = result.filter(el =>  el.type == '影像风采' ).slice(0,6);
      })
    },
       /**
     * @description: 清除html标签
     */

  clearHtml(text){
    return clearHtml(text)
  }
  },
};
</script>

<style lang="scss" scoped>
.news-home {
  display: flex;
  flex-direction: column;
  padding-bottom: 4.1667vw;
  .news-home-ul {
  color: $color-text;

  li {
    .news-home-ul-text {
      width: 100%;
      display: flex;
      flex-direction: column;
      padding-left: 10.8333vw;
      padding-right: 10.8333vw;
      padding-top: 2.0833vw;
      .news-home-ul-article {
        color: rgba(255, 255, 255, 0.4);
        width: 78.3333vw;
        // max-width: ;
        height: 15.625vw;
        margin-bottom: 1.25vw;
        cursor: pointer;
        display: flex;
        justify-content: flex-start;
        position: relative;
        .ml10 {
          position: absolute;
          bottom: .7813vw;
          right: 1.0417vw;
          display: none;
    transition: all 0.3s ease-in-out 0s; // 动画

        }
        &:hover {
          background-color: #172820;
          .ml10 {
            display: block;
          }
          .news-home-ul-article-img img{
            transform: scale(1.1);
          }
        }

        .news-home-ul-article-img {
          overflow: hidden;
          width: 20.8333vw;
          height: 15.625vw;
          display: block;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            transition: all 0.4s ease-in-out 0s; // 动画

          }
        }

        .news-home-ul-article-info {
          width: 78.3333vw;
          height: 100%;
          padding-left: 2.0833vw;
          padding-bottom: 1.3021vw;
          .news-home-ul-article-time {
            margin-top: 25;

            width: 7.2917vw;
            height: 3.3333vw;
            display: flex;
            align-items: center;
            .year {
              width: 1.0938vw;
              height: 2.0833vw;
              background-color: #1DBF73;
              writing-mode:vertical-rl; // 竖排文字
              transform: rotate(180deg);
              text-align: center;
              line-height: 1.0938vw;
              font-size: .8333vw;
              color: #fff;
            }
            div {
              display: flex;
              flex-direction: column;
              margin-left: .4167vw;
              justify-content: space-between;
             .month  {
              width: 3.6979vw;
              height: 1.6146vw;
              font-size: 1.25vw;
              color: #FFFFFF;
            }
            .day {
              width: 2.6042vw;
              height: 1.25vw;
              font-size: .9375vw;
              color: #B3B3B3;
            }
            }

          }
          h2 {
            height: 1.6146vw;
            color: #FFFFFF;
            font-size: 1.25vw;
            margin-top: 1.0417vw;
            margin-bottom: 1.25vw;
            overflow: hidden;text-overflow: ellipsis;
          }
        }

        h4 {
          font-size: .7292vw;
          margin-bottom: 1.3542vw;
          width: 100%;
          font-weight: normal;
          color: #B3B3B3;
          overflow: hidden;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 6;
          text-overflow: ellipsis;

        }
        .news-home-more {
          height: 0;
          display: block;

          overflow: hidden;
          color: $color-active;
        }

      }
    }
    .news-home-ul-img {
      width: 50%;
      height: 100%;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}
.news-box-info {
  display: flex;
  justify-content: space-between;
  position: relative;

  .news-info-left {
    cursor: pointer;
  width: 51.9792vw;
  height: 35vw;
  position: relative;

  .news-info-time {
      position: absolute;
      top: .5208vw;
      left: 0;

  }
  .imgs {
    width: 51.9792vw;
  height: 35vw;
  overflow: hidden;
  position: absolute;
  }
  &:hover {
        .bg {
           transform: scale(1.1);
        }
   .ml10 {
    display: block;
  }
}
    .bg {
    width: 100%;
    height: 100%;
    transition: all 0.4s ease-in-out 0s; // 动画
}
.ml10 {
          display: none;
          z-index: 999 !important;
          position: absolute;
          right:  2% !important;
          bottom: 5%;
          width: 50px;
    transition: all 0.3s ease-in-out 0s; // 动画

}
  }
  h3 {
    padding-right: 1.5625vw;

  }
  }
  .news-info-right{
    cursor: pointer;
    width: 24.6875vw;
    height: 16.6667vw;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .news-info:nth-child(1) {
      margin-bottom: 1.6667vw;
    }
    .news-info {
      position: relative;
      &:hover {
        .ml10{
          display: block;
        }
        .imgs {
          img {
            transform: scale(1.1);
          }
        }

}
    .news-info-time {
      position: absolute;
      top: .5208vw;
      left: 0;
  }
    }
    .imgs{
      width: 24.6875vw;
      height: 16.6667vw;
    overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        transition: all 0.4s ease-in-out 0s; // 动画

    }
    }
    h3 {
    padding-right: 2.6042vw;
    .ml10 {
      position: absolute;
      right: 1.0417vw;
      top:50%;
      display: none;
    transition: all 0.3s ease-in-out 0s; // 动画

    }
  }

  }

}
.images-info {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;

  .images-info-box {
    margin-right: .7813vw;
    width: 25vw;
    height: 16.6667vw;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    .ml10 {
          position: absolute;
          width: 2.0833vw;
          height: .5729vw;
          bottom: 9%;
          right: 1.0417vw;
          display: none;
    transition: all 0.3s ease-in-out 0s; // 动画

        }
&:hover {
  .ml10{
            display: block;
        }
           img {
            transform: scale(1.1);
          }

}
      h3 {
          padding-right: 3.125vw;
      overflow: hidden;

        }
    .news-info-time {
      position: absolute;
      top: .25vw;
      left: 0;
      width: 7.2917vw;
      height: 3.3333vw;
      div {
        height: 2.8646vw;

      }
    }
    img {
      width: 100%;
      height: 100%;
      transition: all 0.4s ease-in-out 0s; // 动画

    }

  }
  .images-info-box:nth-child(1),.images-info-box:nth-child(3),.images-info-box:nth-child(2) {
    margin-bottom: 1.6667vw;

  }
}

.news-info-time {
            width: 7.2917vw;
            height: 3.3333vw;
            display: flex;
            align-items: center;
            background-color: rgba(0, 0, 0, .6);
            padding-left:.8333vw;

            .year {
              width: 1.0938vw;
              height: 2.0833vw;
              background-color: #1DBF73;
              writing-mode:vertical-rl;
              transform: rotate(180deg);
              text-align: center;
              line-height: 1.0938vw;
              font-size: .8333vw;
              color: #fff;
            }
            div {
              display: flex;
              flex-direction: column;
              flex:1;
              margin-left: .4167vw;
              justify-content: space-between;
             .month  {
              width: 3.6979vw;
              height: 1.6146vw;
              font-size: 1.25vw;
              color: #FFFFFF;
            }
            .day {
              width: 2.6042vw;
              height: 1.25vw;
              font-size: .0094vw;
              color: #B3B3B3;
            }
            }
  }
  h3 {
    width: 100%;
    height: 3.6458vw;
    line-height: 3.6458vw;
    background-color: rgba(0, 0, 0, .6);
    color: #FFFFFF;
    font-size: 1.25vw;
    position: absolute;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-left: 1.0417vw;
    bottom: 0;

  }

  @media only screen and (max-width: $mobile-width) {
    .news-home {
      display: flex;
      flex-direction: column;
      padding-bottom: .7339rem;
  .news-home-ul {
  color: $color-text;

  li {
    .news-home-ul-text {
      width: 100%;
      display: flex;
      flex-direction: column;
      padding-left: 0.5rem;
      padding-right: 0.5rem;
      padding-top: 60px;
      .news-home-ul-article {
        color: rgba(255, 255, 255, 0.4);
        width: 100%;
        height: 1.4679rem;
        margin-bottom: .2202rem;
        cursor: pointer;
        display: flex;
        justify-content: flex-start;
        position: relative;
        .ml10 {
          position: absolute;
          bottom: .0688rem;
          right: .0917rem;
          display: none;
    transition: all 0.3s ease-in-out 0s; // 动画

        }
     &:hover {
      background-color: #161616;
      .ml10{
        display: none;
      }
        }

        .news-home-ul-article-img {
          width: 1.8349rem;
          height:100%;
          display: block;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        .news-home-ul-article-info {
          width: 100%;
          height: 100%;
          padding-left: .1835rem;
          padding-bottom: .156rem;
          .news-home-ul-article-time {
            margin-top: 10px;

            width: .6422rem;
            height: .2936rem;

            display: flex;
            align-items: center;
            .year {
              width: .1835rem;
              height: .5505rem;
              background-color: #1DBF73;
              writing-mode:vertical-rl; // 竖排文字
              transform: rotate(180deg);
              text-align: center;
              line-height: .1835rem;
              font-size: .1468rem;
              color: #fff;
            }
            div {
              display: flex;
              flex-direction: column;
              margin-left: .0367rem;
              justify-content: space-between;
             .month  {
              width: 34.9999px;
              height:14.9984px;
              font-size: 12.0009px;
              color: #FFFFFF;
            }
            .day {
              width: 25.0046px;
              height: 12.0009px;
              font-size: 9.0034px;
              color: #B3B3B3;
            }
            }

          }
          h2 {
            height: 14.9984px;
            color: #FFFFFF;
            font-size: .1101rem;
            margin-top: .1835rem;
            margin-bottom: .1101rem;
            overflow: hidden;text-overflow: ellipsis;
          }
        }

        h4 {
          font-size: .0642rem;
          margin-bottom: 13px;
          width: 100%;
          font-weight: normal;
          color: #B3B3B3;
          overflow: hidden;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          text-overflow: ellipsis;
        }
        .news-home-more {
          height: 0;
          display: block;
          overflow: hidden;
          color: $color-active;
        }

      }
    }
    .news-home-ul-img {
      width: 50%;
      height: 100%;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}
.news-box-info {
  display: flex;
  justify-content: space-between;
  position: relative;

  .news-info-left {
    cursor: pointer;
  width: 3.6697rem;
  height: 3.0826rem;
  position: relative;

  .news-info-time {
      position: absolute;
      top: .0917rem;
      left: 0;

  }
  .imgs {
    width: 3.6697rem;
  height: 3.0826rem;
  overflow: hidden;

    img {
    width: 100%;
    height: 100%;
}
  }
  h3 {
    padding-right: .1376rem;
    .ml10 {
      position: absolute;
      right: .2294rem;
      top:50%;
    transition: all 0.3s ease-in-out 0s; // 动画

      display: none;
    }
  }
  }
  .news-info-right{
    cursor: pointer;
    width: 2.1743rem;
    height: 1.4679rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .news-info:nth-child(1) {
      margin-bottom: .1468rem;
    }
    .news-info {
      position: relative;

    .news-info-time {
      position: absolute;
      top: .0917rem;
      left: 0;
  }
    }
    .imgs{
      width: 2.1743rem;
      height: 1.4679rem;
  overflow: hidden;

      img {
        width: 100%;
        height: 100%;
    }
    }
    h3 {
    padding-right: .2294rem;
    .ml10 {
      position: absolute;
      right: .0917rem;
      top:50%;
      display: none;
    transition: all 0.3s ease-in-out 0s; // 动画

    }
  }

  }

}
.images-info {
  width: 100%;
  min-height: 0.5rem;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  .images-info-box {
    width: 2.825rem;
    height: 2.05rem;
    position: relative;
    cursor: pointer;
    .ml10 {

          display: none;
        }
&:hover .ml10{
            display: none;
        }
        h3 {
          padding-right: .2752rem;
        }
    .news-info-time {
      position: absolute;
      top: 10px;
      left: 0;
      width:  1.2844rem;
      height: .6872rem;
      div {
        height: .367rem;
      }
    }
    img {
      width: 100%;
      height: 100%;
    }

  }
  .images-info-box:nth-child(1),.images-info-box:nth-child(3),.images-info-box:nth-child(2) {
    margin-bottom: .1468rem;

  }
}
}
.news-info-time {
            width: 1.2844rem;
            height: .6872rem;
            display: flex;
            align-items: center;
            background-color: rgba(0, 0, 0, .6);
            padding-left:.1468rem;

            .year {
               width: .1927rem;
              height: .5505rem;
              background-color: #1DBF73;
              writing-mode:vertical-rl;
              transform: rotate(180deg);
              text-align: center;
              line-height: .1927rem;
              font-size: .1468rem;
              color: #fff;
            }
            div {
              display: flex;
              flex-direction: column;
              flex:1;
              margin-left: .0734rem;
              justify-content: space-between;
             .month  {
              width: .3211rem;
              height: 16.0012px;
              font-size: .1101rem;
              color: #FFFFFF;
            }
            .day {
              width: .2294rem;
              height: 12.0009px;
              font-size: .1101rem;
              color: #B3B3B3;
            }
            }
  }
  h3 {
    width: 100%;
    height: .3211rem;
    line-height: .3211rem ;
    background-color: rgba(0, 0, 0, .6);
    color: #FFFFFF;
    font-size: .1101rem;
    position: absolute;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-left: .0917rem;
    bottom: 0;

  }

  }
//   @media only screen and (min-width: 100vw) {
//  .news-home {
//   .news-home-ul-text {
//     display: flex;
//     justify-content: center;
//   }
//   .news-home-ul-article-info {
//     width: 100%;
//     background-color: red !important;
//   }
//   h4 {
//     background-color: red !important;
//   }
//  }

//   }

</style>
